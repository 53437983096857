import React, { Component } from 'react';
import HadithData from '../hadith-data/hadith-data';
import './hadith-of-the-day.css';
import AppConfig from '../app-config/app-config';

export default class HadithOfTheDay extends Component {
  constructor(props) {
    super(props);
    this.hadithData = new HadithData();

    var _appConfig = new AppConfig();
    var background = _appConfig.get('hadith_background_color');
    //hadith_text_color
    var color = _appConfig.get('hadith_text_color');

    this.state = {
      hadith: this.hadithData.getCurrentDayHadith(),
      background: background,
      color: color
    };
  }
  render() {
    return (
      <div
        className="HadithOfTheDayWrapper"
        style={{
          backgroundColor: this.state.background,
          color: this.state.color
        }}
      >
        <div className="HadithOfTheDayTitle">
          <h1>Hadith of the day</h1>
        </div>

        <blockquote className="blockquote">
          <p className="Hadith">{this.state.hadith.hadith}</p>
          <footer className="blockquote-footer HadithSource">
            <cite title="Source Title">{this.state.hadith.source}</cite>
          </footer>
        </blockquote>
      </div>
    );
  }
}
